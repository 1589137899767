<!-- START Notification Sidebar-->
<aside class="notification-sidebar d-none d-sm-none d-md-block" id="notification-sidebar" [ngClass]="{open: isOpen}">
  <a class="notification-sidebar-close" (click)="onClose()">
    <i class="ft-x font-medium-3 grey darken-1"></i>
  </a>
  <div class="side-nav notification-sidebar-content" [perfectScrollbar]>
    <div class="row">
      <div class="col-12 notification-tab-content">
        <nav ngbNav #nav="ngbNav" class="nav-tabs">
          <ng-container ngbNavItem class="nav-item">
            <a ngbNavLink class="nav-link">Güncellemeler</a>
            <ng-template ngbNavContent>
              <div class="row tab-pane" id="activity-tab" role="tabpanel" aria-expanded="true"
                aria-labelledby="base-tab1">
                <div class="col-12" id="activity">
                  <h5 class="my-2 text-bold-500">Bildirimler  <button class="btn btn-sm btn-danger float-right" (click)="temizle()">Temizle</button></h5>
                  <div class="timeline-left timeline-wrapper mb-3" id="timeline-1">
                    <ul class="timeline">
                      <li class="timeline-line mt-4"></li>
                      <li class="timeline-item" *ngFor="let sysData of systemNoti">
                        <div class="timeline-badge">
                          <span class="bg-primary bg-lighten-4" data-toggle="tooltip" data-placement="right"
                            title="Portfolio project work">
                            <i [ngClass]="{'ft-info primary': sysData.bildirimturu=='Bilgi','ft-alert-circle warning': sysData.bildirimturu=='Uyari'}" ></i>
                          </span>
                        </div>
                        <div class="activity-list-text">
                          <h6 class="mb-1">
                            <span>{{sysData.bildirimturu}}</span>
                            <span class="float-right grey font-italic font-small-2">{{sysData.olusturmatarihi}} {{sysData.olusturmasaati}}</span>
                          </h6>
                          <p class="mt-0 mb-2 font-small-3">{{sysData.bildirimmetni}}</p>
                          <button  type="button" class="btn btn-info btn-sm mr-1 text-end" (click)="showTask(sysData.gorevid,sysData.turu)">
                            <span>Gör</span>
                        </button>
                        </div>
                      </li>

                    </ul>
                  </div>
                
                </div>
              </div>
            </ng-template>
           </ng-container>
            </nav>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</aside>
<!-- END Notification Sidebar-->
