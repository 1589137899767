import { Component, ViewContainerRef, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import {formatDate} from '@angular/common';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SessionTimerService } from 'session-expiration-alert';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { NewrecordrowComponent } from './newrecordrow/newrecordrow.component';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    alertAt = 15;
    startTimer = true;
    subscription: Subscription;
    bildirimInterval: any;
    cities_psh: any[];
    degerleri: string;
    etiketleri: string;
    mlzemeInterval: NodeJS.Timeout;
    sonkontrolzamani:string;

    constructor( private modalService: NgbModal,private httpClient: HttpClient,public toastr: ToastrService,private router: Router) {
 
        // this.router.events.subscribe((e) => {
        //     if (e instanceof NavigationEnd) {
        //         if(localStorage.getItem("userid")!=null){
        //             this.bildirimInterval =   setInterval(()=>{
        //                 var bildirimurl=localStorage.getItem('url')+"angular/dataservis.php?page_id=pdo_islembildirimlerim&turu=ilk&userid="+localStorage.getItem("userid");
        
        //                 this.httpClient.get<any>(bildirimurl).subscribe(data => {
        //                         if(data.length>0){
        //                             for (let i = 0; i <= data.length; i++) {                                             
        //                                 if(i==data.length){
        //                                     clearInterval(this.bildirimInterval);
                                            
        //                                 }else{
        //                                     if(data[i].bildirimturu=='Bilgi'){
        //                                         this.TypeInfo(data[i].bildirimmetni,data[i].olusturmatarihi+' '+data[i].olusturmasaati,data[i].index_bildirimler);
        //                                     }
        //                                     if(data[i].bildirimturu=='Uyari'){
        //                                         this.TypeWarning(data[i].bildirimmetni,data[i].olusturmatarihi+' '+data[i].olusturmasaati,data[i].index_bildirimler);
        //                                     }
        //                                 }              
        //                             }                           
        //                         }
        //                         else{             
        //                             clearInterval(this.bildirimInterval);
        //                             }
        //                 });
        
        //             },2000);    
        //         }else{

        //         }

 
        //     }
        //   });

      var  currentDate = new Date();

const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


this.router.events.subscribe((e) => {

  
    if (e instanceof NavigationStart) {
      
      if(e.url=='/taskboard' && localStorage.getItem('userid')!='1'){
        //   localStorage.removeItem('degerleri');
        // localStorage.removeItem('etiketleri');
        if (localStorage.getItem("userid") != null) {
          var bildirimurl = localStorage.getItem('url') + "angular/dataservis.php?page_id=pdo_islembildirimlerim&turu=ilk&userid="+localStorage.getItem("userid");
                  //[{b},{}] 2
          this.httpClient.get<any>(bildirimurl).subscribe(data => {
              if (data.length > 0) {
                  for (let i = 0; i <= data.length; i++) {
                     // console.log('for '+i+'çalıştı'); 
                     if (data[i].bildirimturu == 'Hatırlatma') {

                      //    console.log('else bilgi çalıştı'); 

                       this.TypeInfo(data[i].bildirimmetni, data[i].olusturmatarihi + ' ' + data[i].olusturmasaati, data[i].index_bildirimler,data[i].gorevid,data[i].turu);
                   
                   }
                     if (data[i].bildirimturu == 'Bilgi') {

                      //    console.log('else bilgi çalıştı'); 

                       this.TypeInfo(data[i].bildirimmetni, data[i].olusturmatarihi + ' ' + data[i].olusturmasaati, data[i].index_bildirimler,data[i].gorevid,data[i].turu);
                   
                   }
                   if (data[i].bildirimturu == 'Uyari') {

                      //    console.log('else uyarı çalıştı'); 

                       this.TypeWarning(data[i].bildirimmetni, data[i].olusturmatarihi + ' ' + data[i].olusturmasaati, data[i].index_bildirimler);
                   }

                     //   if (i == data.length) {
                        //  console.log(i+'=='+data.length+' çalıştı clear inter'); 
                     // } else {
                      //    console.log('else'+i+'çalıştı'); 

                     // }
                  }
              }
              else {
                 //    console.log('else clear çalıştı'); 

              }
          });


  } else {

  }
      }else{

      }
    }
});

    
    }
    increase() {
        this.alertAt++;
      }
    ngOnInit() {

        if (localStorage.getItem("userid") === null) {
            this.router.navigate(['']);
        }
                this.subscription = this.router.events
                .pipe(
                    filter(event => event instanceof NavigationEnd)
                )
                .subscribe(() => window.scrollTo(0, 0));
      

      
    }


    editTask(id,formids) {


      var modalSat  = this.modalService.open(NewrecordrowComponent, {backdrop: 'static',size: 'xl', keyboard: false, centered: true});
     
  
  
      modalSat.componentInstance.Modaldata = {mastervalue:'',rowformid:formids,rowrecordid:id,formtanimi:'',subformid:''};
  
      modalSat.result.then((result) => {
    
        if (result=='Yenile')
        {
    
        }
    
     }, (reason) => {
       
     });
    
    }
  
   
  // Info Type
  TypeInfo(message,header,id,gorevid,gorevturu) {
    this.toastr.info(message,header,{"timeOut": 3000, "extendedTimeOut": 0 }).onTap.subscribe(()=>{

      if(gorevid!=''){

          if(gorevturu==''){
            this.editTask(gorevid,'381');
          }else if(gorevturu=='Online'){
            this.editTask(gorevid,'718');
          }else if(gorevturu=='Ziyaret'){
            this.editTask(gorevid,'718');
          }
        

      }else{

        var bildirimokundu = localStorage.getItem('url')+"angular/dataservis.php?page_id=pdo_bildirimiokundu&bildirimid="+id;
        this.httpClient.get<any>(bildirimokundu).subscribe(data => {

        });
      }

    });
  }

  // Warning Type
  TypeWarning(message,header,id) {
    this.toastr.warning(message,header,{"timeOut": 0 , "extendedTimeOut": 0}).onTap.subscribe(()=>{
        
                                   
    });

  }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}

